/* ================================================
--> Elements image style
================================================== */
.image_container {
	width: 100%;
	overflow: hidden;
	&.image_circle {
		border-radius: 50%;
		img {
			max-width: unset;
		}
	}
	img {
		max-width: 100%;
		&.image_rounded {
			border-radius: 4px;
		}
		&.image_bordered {
			padding: 5px;
			border: 1px solid $border-color;
		}
	}
}
#image.element_single .display-single_element {
	>p {
		margin-bottom: 0px;
	}
	.image_container {
		margin-top: 30px;
	}
}
