/*================================================
--> Element Buttons
==================================================*/
#buttons {
	.display-single_element {
		.btn {
			margin: 0px 5px 10px 0;
		}
	}
}
.btn {
	border: none;
	border-radius: 0px;
	height: 40px;
	padding: 0 20px;
	font-size: 14px;
	line-height: 40px;
	font-weight: 400;
	text-transform: uppercase;
	cursor: pointer;
	color: $white-color;
	@include transition(all 0.3s ease-in-out);

	@include desktops {
		font-size: 14px;
	}
	&.focus, &:focus, &.active, &:active, &:hover {
		box-shadow: none;
		@include box-shadow(0px, 12px, 25px, rgba(0, 0, 0, 0.08));
	}
	&.btn-rounded {
		border-radius: 40px;
	}
	i {
		font-size: inherit;
	}
}
// Default buttons======================================
.btn-primary {
	background-color: $primary-color;
	border: 1px solid $primary-color;
	&:hover, &:focus, &.active, &:active{
		background-color: $primary-color!important;
		border: 1px solid $primary-color!important;
	}
}
.btn-secondary {
	background-color: $sand;
	color: $title-color;
	border: 1px solid #dedede;
	&:hover, &:focus, &.active, &:active{
		background-color: $sand;
		border: 1px solid #dedede
	}
}
.btn-success {
	background-color: $success;
	border: 1px solid $success;
	&:hover, &:focus, &.active, &:active{
		background-color: $success;
		border: 1px solid $success;
	}
}
.btn-info {
	background-color: $info;
	border: 1px solid $info;
	&:hover, &:focus, &.active, &:active{
		background-color: $info;
		border: 1px solid $info;
	}
}
.btn-warning {
	background-color: $warning;
	border: 1px solid $warning;
	&:hover, &:focus, &.active, &:active{
		background-color: $warning;
		border: 1px solid $warning;
	}
}
.btn-danger {
	background-color: $danger;
	border: 1px solid $danger;
	&:hover, &:focus, &.active, &:active{
		background-color: $danger;
		border: 1px solid $danger;
	}
}
.btn-inverse {
	background-color: $inverse;
	border: 1px solid $inverse;
	&:hover, &:focus, &.active, &:active{
		background-color: $inverse;
		border: 1px solid $inverse;
	}
}
// Outlined buttons======================================
.btn-primary-outlined {
	background-color: transparent;
	color: $primary-color;
	border: 1px solid $primary-color;

	&:hover, &:focus, &.active, &:active{
		background-color: $primary-color;
		color: $white-color;
	}
}
.btn-secondary-outlined {
	background-color: transparent;
	color: $title-color;
	border: 1px solid #dedede;
	&:hover, &:focus, &.active, &:active{
		background-color: $sand;
		color: $title-color;
	}
}
.btn-success-outlined {
	background-color: transparent;
	color: $success;
	border: 1px solid $success;
	&:hover, &:focus, &.active, &:active{
		background-color: $success;
		color: $white-color;
	}
}
.btn-info-outlined {
	background-color: transparent;
	color: $info;
	border: 1px solid $info;
	&:hover, &:focus, &.active, &:active{
		background-color: $info;
		color: $white-color;
	}
}
.btn-warning-outlined {
	background-color: transparent;
	color: $warning;
	border: 1px solid $warning;
	&:hover, &:focus, &.active, &:active{
		background-color: $warning;
		color: $white-color;
	}
}
.btn-danger-outlined {
	background-color: transparent;
	color: $danger;
	border: 1px solid $danger;
	&:hover, &:focus, &.active, &:active{
		background-color: $danger;
		color: $white-color;
	}
}
.btn-inverse-outlined {
	background-color: transparent;
	color: $inverse;
	border: 1px solid $inverse;
	&:hover, &:focus, &.active, &:active{
		background-color: $inverse;
		color: $white-color;
	}
}


/*================================================
--> Button sizes
==================================================*/
.btn {
	&.btn-sm {
	    padding: 0px 10px;
	    font-size: 12px;
	    height: 30px;
	    line-height: 30px;
	}
	&.btn-mid {
		height: 40px;
		line-height: 40px;
		padding: 0 20px;
	}
	&.btn-lg {
		height: 50px;
		line-height: 50px;
		padding: 0 35px;
	}
	&.btn-default {
		height: 50px;
		line-height: 50px;
		padding: 0 30px;
	}
	&.btn-icon {
		i {
			margin-right: 5px;
		}
	}
}
/*================================================
--> Social Buttons
==================================================*/
.btn-facebook {
	background-color: #3b5999;
}
.btn-twitter {
	background-color: #55acee;
}
.btn-social {
	font-size: 16px;
	width: 35px;
	height: 35px;
	text-align: center;
	padding: 0;
	border-radius: 0;
	margin: 0 auto;
	line-height: 32px;
	display: inline-block;
	i {
		margin-right: 0px;
	}
	&.btn-cicle {
		border-radius: 50%;
	}
	&.rounded {
		border-radius: 4px;
	}
	&.titled {
		text-transform: capitalize;
		width: auto;
		height: 35px;
		border-radius: 0px;
		padding: 0 20px;
		line-height: 35px;
		i {
			margin-right: 5px;
		}
	}
	&.facebook {
		background-color: #3b5999;
	}
	&.twitter {
		background-color: #55acee;
	}
	&.pinterest {
		background-color: #bd081c;
	}
	&.skype {
		background-color: #00aff0;
	}
	&.linkedin {
		background-color: #0077b5;
	}
	&.google-plus {
		background-color: #dd4b39;
	}
	&.youtube {
		background-color: #cc181e;
	}
	&.btn-social-o {
		&:hover {
			background-color: transparent;
			box-shadow: none;
			&.facebook {
				color: #3b5999;
			}
			&.twitter {
				color: #55acee;
			}
			&.pinterest {
				color: #bd081c;
			}
			&.skype {
				color: #00aff0;
			}
			&.linkedin {
				color: #0077b5;
			}
			&.google-plus {
				color: #dd4b39;
			}
			&.youtube {
				color: #cc181e;
			}
		}
	}
	&.outlined {
		background-color: $white-color;
		&.facebook {
			color: #3b5999;
			border: 1px solid;
			border-color: #3b5999;
			&:hover {
				background-color: #3b5999;
				color: $white-color;
			}
		}
		&.twitter {
			color: #55acee;
			border: 1px solid;
			border-color: #55acee;
			&:hover {
				background-color: #55acee;
				color: $white-color;
			}
		}
		&.pinterest {
			color: #bd081c;
			border: 1px solid;
			border-color: #bd081c;
			&:hover {
				background-color: #bd081c;
				color: $white-color;
			}
		}
		&.skype {
			color: #00aff0;
			border: 1px solid;
			border-color: #00aff0;
			&:hover {
				background-color: #00aff0;
				color: $white-color;
			}
		}
		&.linkedin {
			color: #0077b5;
			border: 1px solid;
			border-color: #0077b5;
			&:hover {
				background-color: #0077b5;
				color: $white-color;
			}
		}
		&.google-plus {
			color: #dd4b39;
			border: 1px solid;
			border-color: #dd4b39;
			&:hover {
				background-color: #dd4b39;
				color: $white-color;
			}
		}
		&.youtube {
			color: #cc181e;
			border: 1px solid;
			border-color: #cc181e;
			&:hover {
				background-color: #cc181e;
				color: $white-color;
			}
		}
	}
}

.socialLink {
	a {
		height: 45px;
		width: 45px;
		position: relative;
		display: inline-block;
		font-size: 16px;
		background: transparent;
		color: $text-color;
		line-height: 44px;
		border-radius: 50%;
		border: 1px solid rgba(132, 132, 132, 0.5);

		&:focus, &:hover, &.active, &:active{
			background-color: $primary-color;
			border: 1px solid $primary-color;
			color: $white-color;
			opacity: 1;
		}
		@include tablet {
			height: 30px;
			width: 30px;
			font-size: 14px;
			line-height: 30px;
		}
		@include desktops {
			height: 45px;
			width: 45px;
			font-size: 16px;
			line-height: 44px;
		}
	}
}
.footer-dark {
	.socialLink {
		a {
			color: $white-color;
			border: 1px solid rgba(255, 255, 255, 0.7);
			opacity: 0.7;

			&:focus, &:hover, &.active, &:active{
				background-color: $primary-color;
				border: 1px solid $primary-color;
				color: $white-color;
				opacity: 1;
			}
		}
	}
} 