/*====================================
SERVICES PAGE STYLE
======================================*/
.page-services, .page-service-details {
	.bredcrumb {
		padding: 0px;
	}
}
.services {
	.home-testimonial {
		.bg-image .card {
			@include tablet {
				padding: 25px 0px 0;
			}
			@include desktops {
				padding: 80px 30px;
			}
			@include large-desktops {
				padding: 100px 30px;
			}
		}
	}
}
.service_single-area {
	padding-top: 80px;
	padding-bottom: 80px;
	@include desktops {
		padding-top: 100px;
		padding-bottom: 100px;
	}
	.service_details-desc {
		h2 {
			margin-top: 40px;
		}
		p {
			margin-top: 30px;
		}
		ul.styled_list {
			margin-top: 30px;
			i {
				color: $primary-color;
			}
		}
		h4 {
			margin-top: 40px;
		}
	}
}
.sidebar {
	.list-group {
		margin-bottom: 40px;
		@include tablet {
			margin-bottom: 0;
		}
		.list-group-item {
			margin-top: 10px;
			&:first-child {
				margin-top: 0px;
			}
			a {
				font-size: 14px;
				font-weight: 600;
				position: relative;
				color: $title-color;
				padding-left: 20px;
				&:before {
					position: absolute;
					content: '\f0da';
					font-family: 'FontAwesome';
					left: 0
				}
			}
			&.active {
				a {
					color: $white-color;
				}
			}
		}
	}
}
