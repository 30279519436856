/*=== 1.COMMON ===*/
/* Font faces */
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700|Open+Sans:400,400i,600');

.container{
	position:relative;
	width: 100%;
	@include mobile {
		width: 450px;
	}
	@include tablet {
		width: 750px;
	}
	@include desktops {
		width: 970px;
	}
	@include large-desktops {
		width: 1140px;
	}
}

a{
	color: $text-color;
	text-decoration: none;
	@include transition(all 0.3s ease-in-out);
	&:hover, &:focus{
		outline: 0;
		color: $primary-color;
		text-decoration: none;
	}
}
.main-wrapper {
	overflow-x: hidden;
}
.boxed {
	.main-nav {
		@include tablet {
			box-shadow: none;
		}
	}
	.main-wrapper {
		position: relative;
		background-color: $white-color;
		margin: 0 auto;
		overflow-x: hidden;
		@include mobile {
			max-width: 520px;
		}
		@include tablet {
			padding: 0px 0 0;
			max-width: 728px;
		}
		@include desktops {
			padding: 0px 0 0;
			max-width: 970px;
		}
		@include large-desktops {
			padding: 0px 0 0;
			max-width: 1260px;
		}
	}
	.home_transparent-wrapper {
		&.main-wrapper {
			padding-top: 0px;
		}
	}
	.navbar-sticky {
		@include mobile {
			max-width: 520px;
			left: 50%;
			@include transform(translateX(-50%));
		}
		@include tablet {
			padding: 25px 0 0;
			max-width: 728px;
		}
		@include desktops {
			padding: 80px 0 0;
			max-width: 970px;
		}
		@include large-desktops {
			padding: 80px 0 0;
			max-width: 1260px;
		}
	}
}
.list-inline {
	li {
		display: inline-block;
	}
}
.position_unset {
	> .offset-md-1.col-md-10 {
		position: unset;
		> .row {
			position: unset;
		}
	}
}
.visible {
	display: block!important;
}
.hidden {
	display: none;
}
.form-control:focus{
	box-shadow: none;
}
.btn-cta {
	color: $title-color!important;
	background-color: $white-color;
}
.section {
	padding-top: 80px;
	@include desktops {
		padding-top: 100px;
	}
}

.sectionTitle {
	position: relative;
	h2{
		line-height: 1;
		padding-bottom: 8px;
		color: $title-color;
		margin: 0;
		@include mobile-xs {
			font-size: 30px;
			letter-spacing: 0px;
		}
		@include tablet {
			font-size: 34px;
			letter-spacing: 0px;
		}
	}
	p {
		opacity: 0.7;
	}
}
.img-full {
	width: 100%;
}
.img-circle {
	position: relative;
	border-radius: 50%;
}
.bg-image-holder, .bg-image {
	background-size: cover !important;
	background-position: 50% 50% !important;
	@include transition(all 0.3s linear);
	background: $sand;
	position: relative;
}
.bg-image {
	&:before {
	    content: '';
	    position: absolute;
	    top: 0;
	    left: 0;
	    right: 0;
	    bottom: 0;
	    background-color: rgba(10, 35, 59, 0.85);
	}
}
.bg-gradient-navy {
	background-image: -moz-linear-gradient( 0deg, rgb(10,35,59) 0%, rgb(26,53,80) 50%, rgb(10,35,59) 100%);
	background-image: -webkit-linear-gradient( 0deg, rgb(10,35,59) 0%, rgb(26,53,80) 50%, rgb(10,35,59) 100%);
	background-image: -ms-linear-gradient( 0deg, rgb(10,35,59) 0%, rgb(26,53,80) 50%, rgb(10,35,59) 100%);
	background-image: linear-gradient( 0deg, rgb(10,35,59) 0%, rgb(26,53,80) 50%, rgb(10,35,59) 100%);
}
::selection {
	background-color: $primary-color;
	color: $white-color;
}
.bg-sand {
	background-color: $sand;
}
.bg-primary {
	background-color: $primary-color!important;
}
.bg-navy {
	background-color: $navy!important;
}
.bg-dark-navy {
	background-color: $dark-navy!important;
}
.no-bg {
	background-color: transparent;
}
.bordered {
	border: 1px solid $border-color;
}
// all Margins
.mb30 {
	margin-bottom: 30px;
}
// all Padding
.no-padding {
	padding: 0!important;
}
.pv100 {
	padding-top: 100px;
	padding-bottom: 100px;
}
.pt30 {
	padding-top: 30px;
}
.pb30 {
	padding-bottom: 30px;
}
.pb50 {
	padding-bottom: 50px;
}
.pv30 {
	padding-top: 30px;
	padding-bottom: 30px;
}
.pt100{
	padding-top: 100px;
}
.pb100 {
	padding-bottom: 100px;
}

.bold {
	font-weight: 700!important;
}
.medium {
	font-weight: 500!important;
}

.table-inverse {
	background-color: $navy;
}
.sidebar {
	padding-left: 15px;
	margin-top: 40px;
	@include tablet {
		padding-left: 30px;
		margin-top: 0px;
	}
	@include desktops {
		padding-left: 40px;
		margin-top: 0px;
	}
	&.sidebar-left {
		@include tablet {
			padding-left: 15px;
		}
		@include desktops {
			padding-left: 15px;
		}
	}
}
/*============== Hover Overlays ==============*/
.hover-overlay {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	a.overlay_icon {
		width: 55px;
		height: 55px;
		background: #fff;
		position: relative;
		display: inline-block;
		border-radius: 50%;
		margin: 3px;
		text-align: center;
		i.fa {
			font-size: 20px;
			line-height: 50px;
			color: $text-color;
		}
	}
}
// All Effects ================================
.hover-overlay {
	&.effect-transformY {
		position: absolute;
		content: '';
		background-color: rgba($primary-color, 0.8);
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		@include transform(translateY(-100%));
		@include transition(transform 0.3s ease);
	}
	&.effect-scale {
		@include transition(all 0.4s ease);
		-webkit-transform: scale(0);
		transform: scale(0);
		opacity: 0;
		&:before {
			position: absolute;
			content: '';
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			opacity: 0;
			-webkit-transform: scale(0);
			transform: scale(0);
			-webkit-transition: -webkit-transform 0.4s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.4s;
			transition: transform 0.4s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.4s;
			background: rgba(10,35,59,0.5);
		}
	}
}
.card, .card_img {

	&:hover {
		.hover-overlay.effect-transformY {
			@include transform(translateY(0));
		}
		.hover-overlay.effect-scale {
			-webkit-transform: scale(1);
			transform: scale(1);
			opacity: 1;
			&:before {
				opacity: 1;
				-webkit-transform: scale(1);
				transform: scale(1);
			}
		}
	}
}
.img_overlay-container {
	position: relative;
	padding: 0 30px;
	.img_overlay-title {
		color: $white-color;
		margin-bottom: 10px;
	}
	.img_overlay-desc {
		color: tint($white-color, 70%);
		margin-bottom: 20px;
	}
	a.icon {
		width: 38px;
		height: 38px;
		font-size: 15px;
		line-height: 36px;
		background-color: transparent;
		text-align: center;
		display: inline-block;
		color: $white-color;
		border: 1px solid $white-color;
		margin-right: 5px;
		&:hover {
			background-color: $primary-color;
			border-color: $primary-color;
		}
	}
}
/*
 Deleted effect inpired from http://demo.massivedynamic.co/general/ ============

.hover-overlay {
	position: absolute;
	content: '';
	background-color: rgba($navy, 0.8);
	width: 100%;
    height: 100%;
    bottom: -100%;
    left: -100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	@include transition(all 0.7s ease);
	a.overlay_icon {
		width: 55px;
		height: 55px;
		background: #fff;
		position: relative;
		display: inline-block;
		border-radius: 50%;
		margin: 3px;
		text-align: center;
		i.fa {
			font-size: 20px;
			line-height: 50px;
			color: $text-color;
		}
	}
}
.img_overlay-container {
	position: relative;
	padding: 0 30px;
	.img_overlay-title {
		color: $white-color;
		margin-bottom: 10px;
	}
	.img_overlay-desc {
		color: #aaa;
		margin-bottom: 20px;
	}
	a.icon {
		width: 38px;
		height: 38px;
		font-size: 15px;
		line-height: 36px;
		background-color: transparent;
		text-align: center;
		display: inline-block;
		color: $white-color;
		border: 1px solid #aaa;
		margin-right: 5px;
		&:hover {
			background-color: $primary-color;
			border-color: $primary-color;
		}
	}
	.img_overlay-title, .img_overlay-desc, .img_overlay-icon {
		opacity: 0;
		transform: translateX(-50px);
		transition: opacity .2s,transform .4s;
	}

	.card {
		&:hover {
			.hover-overlay effect-transformY {
				bottom: 0 !important;
				left: 0 !important;
			}
			.img_overlay-container {
				.img_overlay-title, .img_overlay-desc, .img_overlay-icon {
					opacity: 1;
					transform: translateX(0);
					transition: opacity 1s .4s,transform 1.2s .2s;
				}
			}
		}
	}
}
*/
.highlighter {
	background: #282C34;
	padding: 0px 10px;
	border-radius: 4px;
	font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace !important;
	display: inline-block;
}
// BREDCRUMB
.bredcrumb {
	.bg-image {
		padding: 60px 0 60px;
		&:before {
			background-color: rgba(0,0,0,.65);
		}
		h1 {
			color: $white-color;
			position: relative;
			font-weight: 700;
			margin-bottom: 0px;
		}
	}
	.pager.middle {
		text-align: center;
		background-color: #f5f5f8;
		li {
			padding: 20px 5px;
			position: relative;
			text-transform: capitalize;
			color: $title-color;
			font-weight: 600;
			&:after {
				content: "\f105";
				right: -8px;
				top: 20px;
			}
		}
		a {
			color: $primary-color;
		}
	}
}
