* {
    outline: 0;
    margin: 0;
    padding: 0;
}
html, * {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    line-height: 21px;
    color: $text-color;
    font-weight: 400;
}
ul{
	margin:0;
	padding:0;
	li{
		list-style-type:none;
	}
}

p{
  color: $text-color;
  font-weight: 400;
  margin-bottom: 0;
  @include mobile-xs {
    font-size: 15px;
    line-height: 28px;
  }
  @include tablet {
    font-size: 15px;
    line-height: 26px;
  }
}
h1, h2, h3, h4, h5, h6{
  font-family: 'Montserrat', sans-serif;
  text-transform: capitalize;
  margin: 0;
  color: $title-color;
}
h1 {
  font-size: 40px;
  line-height: 1.1;
}
h2{
  font-size: 30px;
  line-height: 1.1;
}
h3{
  font-size: 25px;
  line-height: 1.1;
}
h4{
  font-size: 20px;
  line-height: 1.1; 
}
h5{
  font-size: 18px;
  line-height: 1.1;
}
h6{
  font-size: 16px;
  line-height: 1.1;
}