body.default{
	background-image: url("../img/patterns/pattern1.png");
	background-repeat: repeat;
    background-attachment: fixed;
    background-position: center;
}
body.pattern-01{
	background-image: url("../img/patterns/pattern4.png");
	background-repeat: repeat;
    background-attachment: fixed;
    background-position: center;
}
body.pattern-02{
	background-image: url("../img/patterns/pattern2.png");
	background-repeat: repeat;
    background-attachment: fixed;
    background-position: center;
}
body.pattern-03{
	background-image: url("../img/patterns/pattern3.png");
	background-repeat: repeat;
    background-attachment: fixed;
    background-position: center;
}
body.pattern-04{
	background-image: url("../img/patterns/004.png");
	background-repeat: repeat;
    background-attachment: fixed;
    background-position: center;
}
body.pattern-06{
	background-image: url("../img/patterns/006.png");
	background-repeat: repeat;
    background-attachment: fixed;
    background-position: center;
}
body.pattern-07{
	background-image: url("../img/patterns/007.png");
	background-repeat: repeat;
    background-attachment: fixed;
    background-position: center;
}
body.pattern-05{
	background-image: url("../img/patterns/005.png");
	background-repeat: repeat;
    background-attachment: fixed;
    background-position: center;
}