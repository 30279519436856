/*================================================
--> Element Dropdown
==================================================*/
.dropdown.btn-group {
	.btn-primary {
		color: $white-color;
		&:before  {
			border: none;
		}
	}
	.btn-success {
		color: $white-color;
		&:before {
			border: none;
		}
	}
	.btn-danger {
		color: $white-color;
		&:before {
			border: none;
		}
	}
	.btn-warning {
		color: $white-color;
		&:before {
			border: none;
		}
	}
	.btn-info {
		color: $white-color;
		&:before {
			border: none;
		}
	}
}
.dropdown-toggle {
	color: $title-color;
	&::after {
		display: inline-block;
		width: auto;
		height: auto;
		font-family: "FontAwesome";
		margin-left: 5px;
		vertical-align: middle;
		content: "\f107";
		border: 0px;
		color: inherit;
	}
	&.btn-primary {
		color: $white-color;
	}
	&.focus, &:focus, &.active, &:active, &.active, &:hover {
		box-shadow: none;
	}
}
.dropdown.show {
	>.dropdown-toggle {
		box-shadow: none;
		&::after {
			content: "\f106";
		}
	}
	>.btn-primary.dropdown-toggle {
		background-color: $primary-color !important;
		border: 1px solid $primary-color!important;
		&.focus, &:focus, &.active, &:active, &.active, &:hover {
			background-color: $primary-color!important;
			border: 1px solid $primary-color!important;
		}
	}
	.dropdown-megamenu {
		width: 100%;
		span.title {
			padding: 15px 0 5px;
		    display: inline-block;
		    font-weight: 700;
		}
	}

}
.dropdown-menu {
	@include box-shadow(0px, 0px, 3px, rgba(0, 0, 0, 0.08));
	border: 0px;
	.dropdown-item {
		border-bottom: 1px solid #e5e5e5;
		color: $text-color;
		&:hover {
			background-color: transparent;
			color: $title-color;
		}
		&:last-child {
			border-bottom: 0px;
		}
	}
}
.dropdown {
	&.btn-group {
		.btn {
			margin-top: 10px;
		}
	}
}

