/* ================================================
--> Elements Alert style
================================================== */
.alert {
    padding: 20px;
    margin-bottom: 20px;
    border: 0px solid transparent;
    border-radius: 0;
    font-size: 15px;
    @include tablet {
    	font-size: 18px;
    }
}
.alert-success {
    background-color: tint($success, 75%);
    border-color: $success;
    color: $success;
}
.alert-info {
    background-color: tint($info, 75%);
    border-color: $info;
    color: $info;
}
.alert-warning {
    background-color: tint($warning, 75%);
    border-color: $warning;
    color: $warning;
}
.alert-danger {
    background-color: tint($danger, 75%);
    border-color: $danger;
    color: $danger;
}
.iconic-alert {
	.alert {
		padding: 10px 20px;
	}
	i {
	    height: 40px;
	    width: 40px;
	    display: inline-block;
	    text-align: center;
	    color: $white-color;
	    font-size: 15px;
	    border-radius: 50%;
	    line-height: 40px;
	    margin-right: 10px;
	}
	.alert-success {
		i {
			background: $success;
		}
	}
	.alert-info {
		i {
			background: $info;
		}
	}
	.alert-warning {
		i {
			background: $warning;
		}
	}
	.alert-danger {
		i {
			background: $danger;
		}
	}
}
.alert-dismissible .close {
    position: relative;
    right: -1.25rem;
    padding: 18px 20px;
    color: inherit;
    font-size: 25px;
    opacity: 0.8;
}
