.page-team, .page-member-details {
	.bredcrumb {
		padding: 0px;
	}
}
.member-details {
	padding-top: 80px;
	padding-bottom: 80px;
	@include desktops {
		padding-top: 100px;
		padding-bottom: 100px;
	}
}
.member_designation {
	margin-bottom: 30px;
	h2 {
		margin-bottom: 5px;
		margin-top: 25px;
		@include tablet {
			margin-top: 0;
		}
	}
	span {
		font-style: italic;
	}
}
.member_desc {
	li {
		display: block;
		float: unset;
		width: 100%;
		i {
			color: $primary-color;
			font-size: 14px;
		}
	}
	h4 {
		margin-top: 40px;
	}
	p {
		margin-top: 25px;
	}
}
.media-box {
	margin-bottom: 20px;
	@include desktops {
		margin-bottom: 0px;
	}
}
.member_contact {
	padding: 40px;
	position: relative;
	margin-top: 40px;
	.media-icon {
		font-size: 32px;
		color: #dae0e6;
		position: relative;
		width: 30px;
		text-align: center;
		float: left;
		margin-right: 15px;
	}
	.media-content {
		padding-left: 0px;
		float: left;
		h5 {
			font-size: 15px;
		}
		h5, a {
			color: #dae0e6;
		}
	}
	.social-icons {
		@include desktops {
			text-align: right;
		}
		.btn-social {
			width: 40px;
			height: 40px;
			line-height: 40px;
		}
		.btn {
			background-color: transparent;
			border: 1px solid;
			border-color: shade($white-color, 40%);
			color: #dae0e6;
			&:hover {
				background-color: $primary-color;
				border-color: $primary-color;
				opacity: 1;
			}
		}
	}
}
